import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Check from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { ButtonBase, Collapse } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";
import * as config from "../../../config/config";

// Scrollbar Component //
export const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 3,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      borderRadius: 8,
      backgroundColor: Color.lightHiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      height: "3.25rem",
    },
    "& ::-webkit-scrollbar-track-piece:end": {
      backgroundColor: "transparent",
      marginBottom: "1.5rem",
      marginTop: "1.5rem",
    },
    "& ::-webkit-scrollbar-track-piece:start": {
      backgroundColor: "transparent",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
}));

// Accordion Components //
export const AccordionStyled = styling(Accordion)((props) => ({
  width: "100%",
  maxHeight: "26.25rem",
  overflowX: "hidden",
  overflowY:
    props.expanded && (props.$index === 0 || props.$index === 1)
      ? "scroll"
      : "hidden",
  border:
    props.$job_filter_index === props.$index
      ? `2px solid ${Color.hiredlyPurple}`
      : `2px solid ${Color.darkGrey}`,

  "&.MuiAccordion-root": {
    borderRadius: "2rem",

    ":before": {
      height: "0px",
    },
  },
}));

export const AccordionDetailsStyled = styling(AccordionDetails)({
  //
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const AccordionSummaryStyled = styling(AccordionSummary)({
  minHeight: "auto",
});

// Checkbox Component //
export const CheckboxStyled = styling(Checkbox)({
  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

// Box Components //
export const UncheckedIcon = styling(Box)({
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid" + Color.black,
});
export const CheckedIcon = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.black,
});
export const BoxStyled = styling(Box)((props) => ({
  display: "flex",
  gap: "1rem",
  maxHeight: "3rem",
  width: "100%",

  [props.theme.breakpoints.down("lg")]: {
    maxHeight: "2.5rem",
    marginLeft: "0",
    gap: "0.5rem",
  },

  [props.theme.breakpoints.down("tablet")]: {
    maxWidth: "490px",
    justifyContent: "center",
  },
}));

export const JobSearchOptionsMenuBox = styling(Box)({});

export const ToggleStyled = styling(Box)((props) => ({
  backgroundColor: Color.white,
  color: Color.darkGrey,

  display: "flex",
  alignItems: "center",

  gap: "0.5rem",
  borderRadius: "0.625rem",
  padding: "0 0 0 0.875rem",

  border: "0px",
  boxShadow: `0 0 0 1px ${Color.darkGrey}`,

  "&:hover": {
    border: `0px`,
    boxShadow: `0 0 0 1.5px ${Color.black}`,
    backgroundColor: Color.white,
    color: Color.black,
    transition: "all 0.25s ease-in-out",
    cursor: "pointer",
  },

  ...(props.$active && {
    border: `0px`,
    boxShadow: `0 0 0 2px ${Color.hiredlyPurple}`,
    color: Color.hiredlyPurple,
    backgroundColor: Color.lightHiredlyPurple,
    transition: "all 0.25s ease-in-out",
  }),
}));

export const MobileToggleStyled = styling(Box)((props) => ({
  backgroundColor: Color.white,
  display: "flex",
  alignItems: "center",
  gap: "0.35rem",
  fontSize: "0.875rem",
  borderRadius: "0.625rem",
  padding: "0.875rem",

  border: "0px",
  boxShadow: `0 0 0 1px ${Color.black}`,
  color: Color.black,
  transition: "all 0.25s ease-in-out",

  [props.theme.breakpoints.down("lg")]: {
    height: "2.5rem",
    padding: "0 0 0 0.5rem",

    ...(props.$active && {
      border: `0px`,
      boxShadow: `0 0 0 2px ${Color.hiredlyPurple}`,
      color: Color.hiredlyPurple,
      backgroundColor: Color.lightHiredlyPurple,
      transition: "all 0.25s ease-in-out",
    }),
  },

  [props.theme.breakpoints.down("tablet")]: {
    justifyContent: "center",
    padding: "0 0 0 0.75rem",
  },
}));

// Grid Components //
export const GridSearchOptions = styling(Grid)((props) => ({
  display: "flex",
  flex: "1",
  overflow: "auto",
  width: "100%",
  margin: "0 0 0 1.25rem",

  [props.theme.breakpoints.between("tablet", "lg")]: {
    marginLeft: "0",
  },
}));

export const FilterCategory = styling(Grid)((props) => ({
  display: "flex",
  alignItems: "baseline",
  whiteSpace: "nowrap",
  margin: props.$type === "toggle" ? "0.15rem 1.25rem 0 0" : "",
}));

export const SelectAllGrid = styling(Grid)({
  //
});

export const MinSalaryWrapper = styling(Grid)({
  padding: "2.5rem 0 0",
});

export const SliderContainer = styling(Grid)({
  maxWidth: "18.75rem",
  margin: "auto",
  padding: "1.25rem 0",
});

export const CancelApplyButtonContainer = styling(Grid)({
  position: "sticky",
  bottom: "0",
  backgroundColor: Color.white,
  borderRadius: "1rem",
  padding: "1rem 0",
});

// Typography Components //
export const LabelStyled = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`);

export const CountContainer = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: Color.lightHiredlyPurple,
  borderRadius: "0.25rem",
  border: `0.8px solid ${Color.hiredlyPurple}`,

  fontSize: "0.875rem",
  lineHeight: "1.5rem",

  height: "1.5rem",
  padding: "0 0.5rem",

  margin: "0",
});

export const FilterLabel = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`);

export const ContentTextStyled = styling(Typography)({
  textTransform: "capitalize",
  justifyContent: "center",
  display: "flex",
  fontSize: FontSize.desktopCaption,
  fontWeight: "bold",
  letterSpacing: "0.03rem",
});

export const TooltipTextStyled = styling(Typography)({
  fontWeight: "600",
  fontSize: "0.65rem",
  lineHeight: "0.85rem",
  marginBottom: "0.45rem",
});

export const SnackbarTextStyled = styling(Typography)({
  fontSize: "0.875rem",
  lineHeight: "1.15rem",
  marginBottom: "0.45rem",
});

export const LinkStyled = styling(Link)({
  fontSize: "0.875rem",
  fontWeight: "600",
  fontSize: "0.65rem",
  cursor: "pointer",
});

// Button Component //
export const ButtonStyled = styling(Button)((props) => ({
  backgroundColor: Color.white,
  display: "flex",
  gap: props.$type === "toggle" ? "0.5rem" : "0.75rem",
  fontSize: "0.875rem",
  borderRadius: "0.625rem",
  padding: props.$type === "toggle" ? "0 0 0 0.875rem" : "0.875rem",

  border: `0px`,
  boxShadow: `0 0 0 1px ${Color.darkGrey}`,
  color: Color.darkGrey,

  "&:hover": {
    border: `0px`,
    boxShadow: `0 0 0 1.5px ${Color.black}`,
    backgroundColor: Color.white,
    color: Color.black,
    transition: "all 0.25s ease-in-out",

    "& .filter-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/filter-icon-hover.svg)`,
    },

    "& .internship-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/internship-icon-hover.svg)`,
    },
  },

  ...(props.$active && {
    border: `0px`,
    boxShadow: `0 0 0 2px ${Color.hiredlyPurple}`,
    color: Color.hiredlyPurple,
    backgroundColor: Color.lightHiredlyPurple,
    transition: "all 0.25s ease-in-out",

    "& .filter-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/filter-icon-active-2.svg)`,
    },

    "& .internship-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/internship-icon-active.svg)`,
    },
  }),
}));

export const MobileButtonStyled = styling(Button)((props) => ({
  backgroundColor: Color.white,
  display: "flex",
  gap: "0.35rem",
  fontSize: "0.875rem",
  borderRadius: "0.625rem",
  padding: "0.875rem",

  border: `0px`,
  boxShadow: `0 0 0 1px ${Color.black}`,
  color: Color.black,
  transition: "all 0.25s ease-in-out",

  [props.theme.breakpoints.down("lg")]: {
    height: "2.5rem",
    padding: "0.5rem",

    "& .filter-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/filter-icon-hover.svg)`,
    },

    "& .internship-icon": {
      backgroundImage: `url(${config.assetDomain}/images/hiredly/internship-icon-hover.svg)`,
    },

    ...(props.$active && {
      border: `0px`,
      boxShadow: `0 0 0 2px ${Color.hiredlyPurple}`,
      color: Color.hiredlyPurple,
      backgroundColor: Color.lightHiredlyPurple,
      transition: "all 0.25s ease-in-out",

      "& .filter-icon": {
        backgroundImage: `url(${config.assetDomain}/images/hiredly/filter-icon-active-2.svg)`,
      },

      "& .internship-icon": {
        backgroundImage: `url(${config.assetDomain}/images/hiredly/internship-icon-active.svg)`,
      },
    }),
  },

  [props.theme.breakpoints.down("tablet")]: {
    justifyContent: "center",
    maxWidth: "2.5rem",
    minWidth: "2.5rem",
    padding: 0,
  },
}));

export const SelectAllButton = styling(Button)({
  fontWeight: "700",
  textTransform: "capitalize",
  paddingLeft: "1rem",
  color: Color.hiredlyPurple,
});

export const CancelButton = styling(Button)((props) => ({
  textTransform: "capitalize",
  fontWeight: "700",
  color: Color.black,
  borderRadius: "1.25rem",
  width: "7.5rem",
  border: `1.5px solid ${Color.black}`,

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
    color: Color.white,
    border: `1.5px solid ${Color.hiredlyPurple}`,
  },

  [props.theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export const ApplyButton = styling(ButtonBase)((props) => ({
  textTransform: "capitalize",
  fontWeight: "700",
  backgroundColor: "#2e2e2e",
  color: Color.white,
  borderRadius: "1.25rem",
  width: "7.5rem",
  fontSize: "0.875rem",

  "&:hover": {
    backgroundColor: Color.black,
  },

  [props.theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

// Switch Component //
export const JobPreferenceToggle = styling(Switch)((props) => ({
  width: "2.75rem",
  height: "1.5rem",
  padding: "0",
  margin: "0 0.5rem 0 0",

  "& .MuiSwitch-switchBase": {
    padding: "0",
    margin: "0.2rem",
    transitionDuration: "125ms",

    "&.Mui-checked": {
      transform: "translateX(1.25rem)",
      color: Color.white,

      "& + .MuiSwitch-track": {
        backgroundColor: Color.hiredlyPurple,
        opacity: "1",
        border: "0",
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.5",
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: Color.hiredlyPurple,
      border: "6px solid #fff",
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color: Color.darkGrey,
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: "0.5",
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "1.1rem",
    height: "1.1rem",
  },

  "& .MuiSwitch-track": {
    borderRadius: "2rem",
    backgroundColor: Color.secondaryGrey,
    opacity: "1",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
}));

// Paper Component //
export const PaperStyled = withTheme(styled(Paper)`
  && {
    background-color: ${(props) =>
      props.$page ? `${Color.lightGrey}` : "transparent"};
    display: flex;
    padding: 0;
    min-width: 23rem;
    justify-content: flex-start;
    border-radius: 3.25rem;

    ${(props) => props.theme.breakpoints.between("mobileL", "tabletS")} {
      width: 60%;
    }
  }
`);

// Menu Components //
export const FilterMenu = styling(Menu)((props) => ({
  overflowX: "hidden",
  overflowY: "hidden",

  "& .MuiMenu-paper": {
    width: "23rem",

    padding: "1rem",
    border: `0.125rem solid ${Color.black}`,

    [props.theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  //Target the menu container only
  ".MuiPaper-elevation8": {
    top: `${props.$position.top + 48}px !important`,
  },
  [props.theme.breakpoints.up(768)]: {
    ".MuiPaper-elevation8": {
      top: `${props.$position.top + 55}px !important`,
    },
  },
  [props.theme.breakpoints.up(1280)]: {
    ".MuiPaper-elevation8": {
      top: `${props.$position.top + 60}px !important`,
    },
  },
  ".MuiList-root": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },

  "& .MuiPaper-rounded": {
    borderRadius: "1.5rem",
    marginBottom: "1rem",
  },

  "& .MuiList-root": {
    padding: "0",
  },

  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const FilterMenuItem = styling(MenuItem)({
  padding: "0.5 0",
});

export const MenuStyled = styling(Menu)({
  "& .MuiMenu-paper": {
    minWidth: "28.25rem",
    maxWidth: "28.25rem",
    maxHeight: "34.5rem",
    padding: "1rem 0.5rem 0 0.5rem",
  },

  "& .MuiPaper-rounded": {
    borderRadius: "1.25rem",
  },

  "& .MuiList-root": {
    padding: "0",
  },
});

export const MenuItemStyled = styling(MenuItem)({
  padding: "0 0.5rem",

  "& fieldset": {
    width: "100%",

    "& label": {
      margin: "0",
    },
  },
});

// Icon Components //
export const FilterIcon = styling("span")((props) => ({
  backgroundImage: `url(${config.assetDomain}/images/hiredly/filter-icon-default-1.svg)`,
  backgroundRepeat: "no-repeat",
  transition: "all 0.25s ease-in-out",

  width: "1.125rem",
  height: "1.125rem",
}));

export const InternshipIcon = styling("span")((props) => ({
  backgroundImage: `url(${config.assetDomain}/images/hiredly/internship-icon-default-1.svg)`,
  backgroundRepeat: "no-repeat",
  transition: "all 0.25s ease-in-out",

  width: "1.875rem",
  height: "1.875rem",
}));

export const ExpandMoreStyled = styling(ExpandMoreIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

export const CheckStyled = styling(Check)({
  color: Color.white,
  width: "14px",
  height: "14px",
});

export const InfoIconStyled = styling(InfoIcon)((props) => ({
  color: Color.black,
  width: "1rem",
  height: "1rem",
  cursor: "pointer",
  margin: "0 0 0 1.5rem",

  [props.theme.breakpoints.down("tablet")]: {
    width: "1.15rem",
    height: "1.15rem",
  },
}));

// Slider Component //
export const SliderStyled = styling(Slider)({
  marginTop: "0.75rem",
  margin: "auto",

  "& .MuiSlider-rail": {
    backgroundColor: Color.black,
  },

  "& .MuiSlider-thumb": {
    backgroundColor: Color.black,
  },
});

// Span Component //
export const FilterItemText = styling("span")({
  fontSize: FontSize.desktopCaption,
  whiteSpace: "normal",
});

//Badge Component//

export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge": {
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "0.25rem",

    fontSize: "0.75rem",
    fontWeight: "400",
  },
});

export const BetaTextStyled = styling(Badge)({
  "& .MuiBadge-badge": {
    color: Color.blue,
    fontSize: "0.5rem",
    fontWeight: "bold",
    letterSpacing: "0.025rem",
    marginRight: "-0.75rem",
    marginTop: "0.25rem",
  },
});

//Tooltip Component//

export const TooltipStyled = styling(Tooltip)({});

// Accordion Component //
export const AccordionContainer = styling(Box)({
  width: "100%",
  userSelect: "none",
});

export const AccordionHeader = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",

  backgroundColor: Color.white,
  color: Color.darkGrey,

  border: props.selected
    ? `2px solid ${Color.hiredlyPurple}`
    : `2px solid ${Color.secondaryGrey}`,
  borderRadius: "4rem",

  padding: "0.7rem 1.25rem",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    cursor: "pointer",
    border: `2px solid ${Color.hiredlyPurple}`,
  },
}));

export const AccordionCollapse = styling(Collapse)({
  backgroundColor: Color.white,
  color: Color.black,

  border: `2px solid ${Color.hiredlyPurple}`,
  borderRadius: "1rem",

  marginTop: "0.5rem",
});

export const AccordionContent = styling(Typography)((props) => ({
  maxHeight: "22.65rem",
  overflowX: "hidden",
  overflowY:
    props.panel === "panel1" || props.panel === "panel2" ? "scroll" : "hidden",

  padding: "1.25rem 0.5rem 0 0.5rem",
}));

export const DownArrowIcon = styling(ChevronDownIcon)((props) => ({
  color: props.selected ? Color.hiredlyPurple : Color.darkGrey,

  width: "1.25rem",

  transform: props.selected ? "rotate(180deg)" : "rotate(0deg)",
  transition: "all 0.15s ease-in-out",
}));
