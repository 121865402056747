import { styled as styling } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Color from "../../../../../assets/colors";
import CloseIcon from "@mui/icons-material/Close";

export const ModalContainer = styling("div")(({ theme }) => ({
  padding: "40px 20px",
  position: "relative",
}));

export const TextContainer = styling("div")(({ theme }) => ({
  textAlign: "center",
}));

export const Text = styling("span")((props) => ({
  fontFamily: "Inter",
  fontSize:
    props.$text == "title"
      ? "14px"
      : props.$text == "subtitle"
      ? "14px"
      : props.$text == "message" || props.$text == "messageBoldWithUnderline"
      ? "14px"
      : "12px",
  fontWeight:
    props.$text == "title"
      ? "400"
      : props.$text == "subtitle" || props.$text == "messageBoldWithUnderline"
      ? "700"
      : "400",
  letterSpacing: props.$text == "title" && "1.5px",
  textDecoration: props.$text == "messageBoldWithUnderline" && "underline",
  color: props.$text == "messageBoldWithUnderline" && Color.hiredlyPurple,

  "&:hover": {
    cursor: props.$text == "messageBoldWithUnderline" && "pointer",
  },
}));

export const DialogStyled = styling(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    maxWidth: "1000px",
    width: "85%",
  },
}));

export const TitleContainer = styling("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const CloseIconStyled = styling(CloseIcon)(({ theme }) => ({
  color: Color.secondaryGrey,
  position: "absolute",
  top: "20px",
  right: "25px",

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CircularProgressStyled = styling(CircularProgress)({
  "&.MuiCircularProgress-root": {
    width: "48px !important",
    height: "48px !important",
  },
});

export const LoaderContainer = styling("div")({
  borderRadius: "20px",
  background: Color.lightGrey,
  width: "100%",
  height: "280px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const TextPlaceholderContainer = styling("div")(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));

export const ImageStyled = styling("img")(() => ({
  width: "260px",
  height: "260px",
}));
