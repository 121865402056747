import { Fragment, useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { sendTrackingEvent } from "../../../../../../helpers/tracking_management";
import {
  getJobAppliedAndBookmarkStatus,
  saveJob,
  unSaveJob,
  updateBookmarkedRecommendedJobs,
  updateJobId,
  updateSelectedJob,
} from "../../../../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignInText,
  updateSignUpText,
} from "../../../../../../redux/actions/navbar_action";
import { showSnackBar } from "../../../../../../redux/actions/snackbar_action";
import {
  checkAuthentication,
  getUser,
} from "../../../../../../redux/actions/user_action";
import * as types from "../../../../../../redux/types/job_type";
import Button from "../../../../../shared/SharedButton/SharedButton";
import {
  BookMarkFilledIconStyled,
  BookmarkBorderOutlinedIconStyled,
  ButtonContainer,
  CardContentBox,
  CardContentStyled,
  CardMediaStyled,
  CardStyled,
  CircularProgressStyled,
  CompanyName,
  CompanyNameContainer,
  IconButtonStyled,
  ImageContainer,
  JobTitle,
  JobTitleContainer,
  ListStyled,
  SliderStyled,
  UnorderlistStyled,
} from "./styles";

const sliderSettings = (sliderRef) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidedIndex, setSlidedIndex] = useState(0);

  useEffect(() => {
    if (
      slidedIndex >= sliderRef?.current?.props.children?.length / 3 &&
      slidedIndex < (sliderRef?.current?.props.children.length / 3) * 2
    ) {
      setSelectedIndex(1);
    } else if (
      slidedIndex >=
      (sliderRef?.current?.props.children.length / 3) * 2
    ) {
      setSelectedIndex(2);
    } else {
      setSelectedIndex(0);
    }
  }, [slidedIndex]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    dots: true,
    beforeChange: (prev, next) => {
      setSlidedIndex(next);
    },
    appendDots: (_) => {
      return (
        <div>
          {sliderRef?.current?.props.children.length >= 3 ? (
            <UnorderlistStyled>
              <ListStyled
                $selected={
                  selectedIndex == 0 ||
                  (slidedIndex >= 0 &&
                    slidedIndex < sliderRef?.current?.props.children.length / 3)
                    ? true
                    : false
                }
                onClick={() => {
                  setSelectedIndex(0);
                  sliderRef?.current?.slickGoTo(Number(0));
                }}
              ></ListStyled>
              <ListStyled
                $selected={
                  selectedIndex == 1 ||
                  (slidedIndex >=
                    sliderRef?.current?.props.children.length / 3 &&
                    slidedIndex <
                      (sliderRef?.current?.props.children.length / 3) * 2)
                    ? true
                    : false
                }
                onClick={() => {
                  setSelectedIndex(1);
                  sliderRef.current?.slickGoTo(
                    Number(sliderRef?.current?.props.children.length / 3)
                  );
                }}
              ></ListStyled>
              <ListStyled
                $selected={
                  selectedIndex == 2 ||
                  slidedIndex >=
                    (sliderRef?.current?.props.children.length / 3) * 2
                    ? true
                    : false
                }
                onClick={() => {
                  setSelectedIndex(2);
                  sliderRef.current?.slickGoTo(
                    Number(sliderRef?.current?.props.children.length / 3) * 2
                  );
                }}
              ></ListStyled>
            </UnorderlistStyled>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      );
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
        },
      },
      {
        breakpoint: 774,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 635,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
        },
      },
    ],
  };

  return settings;
};

function RecommendedJobs(props) {
  const dispatch = useDispatch();
  const sliderRef = useRef(null);

  const similarApplicationJob = useSelector(
    (state) => state.jobs.similarApplicationJobs
  );
  const job = useSelector((state) => state.jobs.job);
  const currentJobId = useSelector((state) => state.jobs.currentJobId);
  const bookmarkingJob = useSelector((state) => state.jobs.bookmarkingJob);
  const unbookmarkJob = useSelector((state) => state.jobs.unbookmarkJob);
  const bookmarkedJobs = useSelector((state) => state.jobs.bookmarkedJobs);

  const user = getUser();

  const isSignedIn = (showPopUp) => {
    let signedIn = checkAuthentication();

    if (!signedIn && showPopUp) {
      // Show signin modal
      dispatch(updateShowSignInModalStatus(true));
    }

    return signedIn;
  };

  const onTriggerSaveJob = (similarApplicationJob) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(updateJobId(similarApplicationJob.id));
    let params = {
      jobId: similarApplicationJob.id,
      canCancel: false,
    };
    dispatch(getJobAppliedAndBookmarkStatus(params)).then((response) => {
      // Only trigger when user is logged in to prevent bookmark icon from highlighting
      if (user) {
        if (response.bookmark) {
          dispatch(
            unSaveJob(isSignedIn, similarApplicationJob.id, "recommended-job")
          )
            .then((response) => {
              if (response.type === types.UNSAVE_JOB) {
                let cParams = {
                  message: "Removed job from saved listing successfully.",
                  severity: "success",
                };
                // the snackbar will auto hide without setTimeout
                dispatch(showSnackBar(cParams));

                // do not update selected job if it is a recommended job from pop-up
                if (!origin === "recommended-job" && response.job) {
                  dispatch(updateSelectedJob(response.job));
                }
              }
            })
            .catch(() => {}); // Additional param to determine origin of unsaved job for tracking purposes

          dispatch(
            updateBookmarkedRecommendedJobs(
              bookmarkedJobs.filter(
                (jobId) => jobId !== similarApplicationJob.id
              )
            )
          );
        } else {
          dispatch(
            saveJob(isSignedIn, similarApplicationJob.id, "recommended-job")
          )
            .then((response) => {
              if (response.type === types.SAVE_JOB) {
                let cParams = {
                  message: "Saved job successfully.",
                  severity: "success",
                };
                dispatch(showSnackBar(cParams));

                // do not update selected job if it is a recommended job from pop-up
                if (
                  !origin === "recommended-job" &&
                  response.payload?.bookmarkJob
                ) {
                  dispatch(updateSelectedJob(response.payload.bookmarkJob));
                }
              }
            })
            .catch(() => {}); // Additional param to determine origin of saved job for tracking purposes

          dispatch(
            updateBookmarkedRecommendedJobs((bookmarkedJobs) => [
              ...bookmarkedJobs,
              similarApplicationJob.id,
            ])
          );
        }
      } else {
        dispatch(updateShowSignInModalStatus(true));
        dispatch(updateSignInText("Log In to Save Jobs"));
        dispatch(updateSignUpText("Sign Up to Save Jobs"));
      }
    });
  };

  const trackImpressionInView = (id) => {
    sendTrackingEvent({
      event: "CE_job-impression-job-application-popup",
      "job-id": id,
    });
  };

  return (
    <SliderStyled {...sliderSettings(sliderRef)} ref={sliderRef}>
      {similarApplicationJob.map((similarApplicationJob, index) => {
        const jobUrl = `/jobs/${similarApplicationJob.slug}`;
        return (
          <CardStyled key={index} elevation={4}>
            <ImageContainer>
              <IconButtonStyled
                id={"bookmark-icon-popup-button"}
                aria-label="recommended-job-bookmark"
                onClick={onTriggerSaveJob(similarApplicationJob)}
              >
                {similarApplicationJob.bookmark ||
                bookmarkedJobs.includes(similarApplicationJob.id) ? (
                  unbookmarkJob && similarApplicationJob.id == currentJobId ? (
                    <CircularProgressStyled />
                  ) : (
                    <BookMarkFilledIconStyled />
                  )
                ) : bookmarkingJob &&
                  similarApplicationJob.id == currentJobId ? (
                  <CircularProgressStyled />
                ) : (
                  <BookmarkBorderOutlinedIconStyled />
                )}
              </IconButtonStyled>
              <CardMediaStyled
                component="img"
                image={`https:${similarApplicationJob.image}`}
                height="120"
              />
            </ImageContainer>
            <CardContentStyled>
              <CardContentBox>
                <JobTitleContainer>
                  <InView
                    onChange={(inView) =>
                      inView === true &&
                      trackImpressionInView(similarApplicationJob?.id)
                    }
                  >
                    <JobTitle gutterBottom $title>
                      {similarApplicationJob.title}
                    </JobTitle>
                  </InView>
                </JobTitleContainer>
                <CompanyNameContainer>
                  <CompanyName>
                    {similarApplicationJob.company?.name}
                  </CompanyName>
                </CompanyNameContainer>
              </CardContentBox>

              <ButtonContainer>
                <a
                  href={jobUrl}
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "CE_click-job-job-application-popup",
                      "job-id": similarApplicationJob.id,
                      "job-title": similarApplicationJob.title,
                    });
                  }}
                >
                  <Button
                    id={"view-now-job-button"}
                    button_type={"SolidPurple"}
                    desktop_width={"100%"}
                    bold={"true"}
                    font_size={"12px"}
                    padding={"7.5px 0"}
                  >
                    View Now
                  </Button>
                </a>
              </ButtonContainer>
            </CardContentStyled>
          </CardStyled>
        );
      })}
    </SliderStyled>
  );
}

export default RecommendedJobs;
