import styled, { css } from "styled-components";

export const HeightSpacer = styled.div`
  && {
    ${(props) =>
      props.$height
        ? css`
            height: ${props.$height};
          `
        : css`
            height: 5px;
          `}
  }
`;

export const Spacer = styled.div`
  && {
    ${(props) =>
      props.$width
        ? css`
            width: ${props.$width};
          `
        : css`
            width: 5px;
          `}
  }
`;
