import { FontSize } from "../../../../../../assets/font";
import Color from "../../../../../../assets/colors";
import styled, { css } from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkFilledIcon from "@mui/icons-material/Bookmark";
import Slider from "react-slick";

// Box Component //
export const CardContentBox = styled(Box)`
  && {
    min-height: 100px;
  }
`;

// Grid Components //
export const JobTitleContainer = withTheme(styled(Grid)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      -webkit-line-clamp: 1;
    }
  }
`);

export const CompanyNameContainer = styled(Grid)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const ImageContainer = styled(Grid)`
  && {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ButtonContainer = withTheme(styled(Grid)`
  && {
    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none;
    }
  }
`);

// Typography Components //
export const JobTitle = styled(Typography)`
  && {
    font-weight: ${(props) =>
      props.$title ? `${FontSize.bold}` : `${FontSize.regular}`};
    font-size: ${(props) =>
      props.$title ? `${FontSize.desktopBody}` : `${FontSize.desktopCaption}`};
    text-transform: capitalize;
  }
`;

export const CompanyName = styled(Typography)`
  && {
    font-weight: ${(props) =>
      props.$title ? `${FontSize.bold}` : `${FontSize.regular}`};
    font-size: 12px;
    text-transform: capitalize;
  }
`;

// Card Components //
export const CardStyled = withTheme(styled(Card)`
  && {
    margin: 0px 10px;
    border-radius: 12px;
    min-height: 250px;
  }
`);

export const CardContentStyled = withTheme(styled(CardContent)`
  && {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
`);

export const CardMediaStyled = styled(CardMedia)`
  && {
    z-index: 1;
    position: relative;
    height: 100px;
  }
`;

export const BookmarkBorderOutlinedIconStyled = styled(
  BookmarkBorderOutlinedIcon
)`
  && {
    position: relative;
    color: ${Color.hiredlyPurple};
  }
`;

export const BookMarkFilledIconStyled = styled(BookmarkFilledIcon)`
  && {
    position: relative;
    color: ${Color.hiredlyPurple};
  }
`;

// Icon Button Component //
export const IconButtonStyled = styled(IconButton)`
  && {
    position: absolute;
    padding: 0;
    background: ${Color.white};
    opacity: 0.7;
    padding: 6px;
    z-index: 5;
    top: 15%;
    right: 5%;

    & :hover {
      opacity: 1;
      background: ${Color.white};
    }
  }
`;

// Progress Component //
export const CircularProgressStyled = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

// Slider Components //
export const SliderStyled = withTheme(styled(Slider)`
  && {
    max-width: 95%;

    & .slick-track {
      display: flex;
      touch-action: pan-y;
    }

    .slick-list {
      touch-action: pan-y;
    }

    .slick-slide {
      padding: 0 5px;
    }

    & .slick-slider {
      position: unset;
    }

    & .MuiPaper-root {
      max-width: none;
      padding: 0;
    }

    & .button-slider-next {
      padding: 10px;
      z-index: 1;
      right: 0;
      top: 30%;
      color: ${Color.black};
      visibility: ${(props) => (props.$showNextArrow ? "" : "hidden")};

      & .icon {
        font-size: 2rem;
      }
    }

    & .button-slider-prev {
      padding: 10px;
      z-index: 1;
      left: 0;
      top: 30%;
      color: ${Color.black};
      transform: rotate(180deg);
      visibility: ${(props) => (props.$showPrevArrow ? "" : "hidden")};

      & .icon {
        font-size: 2rem;
      }
    }

    & .button-slider-next:hover,
    .button-slider-prev:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 100%;
      margin: 0 0 1rem 0;
    }
  }
`);

export const UnorderlistStyled = styled.ul`
  && {
    margin: 30px 0 0;
    padding: 0;
  }
`;

export const ListStyled = styled.li`
  && {
    height: 7.5px;
    width: 7.5px;
    border: 1px solid ${Color.hiredlyPurple};
    border-radius: 20px;

    ${(props) =>
      props.$selected &&
      css`
        background: ${Color.hiredlyPurple};
      `}
  }
`;
