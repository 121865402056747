import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../../../config/config";
import { getSimilarApplicationJobs } from "../../../../../redux/actions/job_action";
import { updateInReviewResumePoupupStatus } from "../../../../../redux/actions/user_action";
import { HeightSpacer } from "../../../../shared/Spacer/styles";
import RecommendedJobs from "./RecommendedJobs/RecommendedJobs";
import {
  CircularProgressStyled,
  CloseIconStyled,
  DialogStyled,
  ImageStyled,
  LoaderContainer,
  ModalContainer,
  Text,
  TextContainer,
  TextPlaceholderContainer,
  TitleContainer,
} from "./styles";

function ResumeInReviewPopup(props) {
  const { job } = props;
  const dispatch = useDispatch();

  const isInReviewResumePopupOpen = useSelector(
    (state) => state.user.isInReviewResumeOpen
  );

  const fetchingSimilarApplicationJobs = useSelector(
    (state) => state.jobs.fetchingSimilarApplicationJobs
  );

  const similarApplicationJobs = useSelector(
    (state) => state.jobs.similarApplicationJobs
  );

  const onClosingPopup = () => {
    dispatch(updateInReviewResumePoupupStatus(false));
  };

  useEffect(() => {
    if (isInReviewResumePopupOpen) {
      const params = { perPage: 20, jobId: job.id };
      dispatch(getSimilarApplicationJobs(params));
    }
  }, [isInReviewResumePopupOpen]);

  return (
    <DialogStyled open={isInReviewResumePopupOpen} onClose={onClosingPopup}>
      <ModalContainer>
        <TitleContainer>
          <Text $text={"title"}>RESUME IN REVIEW</Text>
          <CloseIconStyled onClick={onClosingPopup} />
        </TitleContainer>
        <HeightSpacer $height={"10px"} />

        <TextContainer>
          <Text $text={"message"}>
            You can still browse and apply for jobs and your application will be
            submit to the employer once your resume is approved.
          </Text>
          <HeightSpacer $height={"20px"} />

          <Text $text={"message"}>Review your application in your </Text>
          <br />

          <a
            href="/profile#job-application-history/?pending"
            target="_blank"
            rel="noopener"
          >
            <Text $text={"messageBoldWithUnderline"}>
              Job Application Status
            </Text>
          </a>
        </TextContainer>

        <HeightSpacer $height={"20px"} />

        <TextContainer>
          <Text $text={"subtitle"}>View similar jobs you might like</Text>
        </TextContainer>
        <HeightSpacer $height={"10px"} />

        {/* the fetching somehow keeps changing */}
        {similarApplicationJobs.length <= 0 &&
        fetchingSimilarApplicationJobs ? (
          <LoaderContainer>
            <CircularProgressStyled />
          </LoaderContainer>
        ) : similarApplicationJobs.length == 0 ? (
          <TextPlaceholderContainer>
            <ImageStyled
              src={
                config.assetDomain +
                "/images/jobseeker-illustrations/hiredly-job-search-empty-placeholder.svg"
              }
            />
            <Text $text="message">
              Sorry, there are no jobs similar to this current job at the
              moment.
            </Text>
          </TextPlaceholderContainer>
        ) : (
          <RecommendedJobs />
        )}
      </ModalContainer>
    </DialogStyled>
  );
}

export default ResumeInReviewPopup;
